import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutZhhk from './layouts/LayoutZhhk';
// Views 
import Home from './views/Home';
import EngSoftForm from './views/EngSoftForm'
import Submitted from './views/Submitted'
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import ClientTerms from './views/ClientTerms';
import HomeZhhk from './views/HomeZhhk'

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
//    eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

//          <AppRoute path="/privacy" component={Privacy} />

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute path="/free-trial" component={EngSoftForm} />
          <AppRoute path="/submitted" component={Submitted} />
          <AppRoute path="/privacy" component={Privacy} />
          <AppRoute path="/terms" component={Terms} />
          <AppRoute path="/clientterms" component={ClientTerms} />
          <AppRoute path="/zh-hk" component={HomeZhhk} layout={LayoutZhhk} />
        </Switch>
      )} />
  );
}

export default App;
