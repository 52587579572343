import React from 'react';
// import sections
import HeroZhhk from '../components/sections/HeroZhhk';
import FeaturesTilesZhhk from '../components/sections/FeaturesTilesZhhk';
import FeaturesSplitZhhk from '../components/sections/FeaturesSplitZhhk';
import TestimonialZhhk from '../components/sections/TestimonialZhhk';
import CtaZhhk from '../components/sections/CtaZhhk';
import Bubbles from './illustration-section-01.svg'
import { Helmet } from "react-helmet";

let styles = {
  //  margin: '20px',
   width: '100%',
   maxWidth: '100%',
  //  height: '270px',
//    position: 'absolute',
 //   backgroundPosition: 'center top',
//		transform: 'translate(-50%)',
//    opacity: '80%',
    backgroundOrigin:'absolute',
    backgroundSize:'auto',
    backgroundImage: `url(${Bubbles})`,
    backgroundRepeat: `repeat-x`,
    backgroundPosition: '460px 310px',
   // backgroundColor: 'rgba(255, 255, 255, 0.8)'
  };

const HomeZhhk = () => {
//      <FeaturesTiles topDivider />
//      <Testimonial topDivider />
      // <Cta split />
      //      <Hero className="illustration-section-01" />
     //      <FeaturesTiles />
// <TestimonialZhhk topDivider />
  return (
    <>
    
    <Helmet>
      <title>伊索學習 Aesop Learning - 線上兒童閱讀與詞彙課程</title>
      <meta name="robots" content="all"/>
      <link rel="alternate" href="https://www.aesoplearning.com/" hrefLang="en"/>
      <link rel="alternate" href="https://www.aesoplearning.com/zh-hk/" hrefLang="zh"/>
      <link rel="alternate" href="https://www.aesoplearning.com/zh-hk/" hrefLang="zh-HK"/>

    </Helmet>
    
      <HeroZhhk style={styles} />
      <FeaturesSplitZhhk invertMobile imageFill topDivider className="" />
      
      <FeaturesTilesZhhk />
      <CtaZhhk split />

    </>
  );
}

export default HomeZhhk;
