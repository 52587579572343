// JavaScript File
import React from 'react';
import Submit from '../components/sections/Submit';
import { Helmet } from "react-helmet";


const Submitted = () => {
//      <FeaturesTiles topDivider />
//      <Testimonial topDivider />
  return (
    <>
    
    <Helmet>
      <title>Thank You</title>
    </Helmet>
    
      <Submit/>

    </>
  );
}

export default Submitted;