// JavaScript File
import React from 'react';
import TermsText from '../components/sections/TermsText';
import { Helmet } from "react-helmet";

const Terms = () => {

  return (
    <>
    
    <Helmet>
      <title>Terms</title>
      <meta name="robots" content="noindex"/>
    </Helmet>
    
      <TermsText className="" />

    </>
  );
}

export default Terms;