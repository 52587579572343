import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
  //  title: '1 on 1 and small group interactive readings with video chat',
  //  paragraph: 'Research shows that conversational shared storybook reading is one of the best ways to build advanced language skills for kids ages 2-8.'
      title: '1對1線上閱讀課程',
      paragraph: '通過繪本為2-8歲的兒童建立詞彙量、會話以及說故事的技能'

    
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  自然且有趣的學習體驗
                  </div>
                <h3 className="mt-0 mb-12">
                  建立詞彙量
                  </h3>
                <p className="m-0">
                  通過舉例及重複來學習新鮮詞彙，而已掌握的詞彙將被記錄下來並以此為依據為孩子提供切合其閱讀水平的更高階繪本。
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/vocab.svg')}
                //  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} 
                  style={{borderRadius:60/2}}/>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  會話型學習
                  </div>
                <h3 className="mt-0 mb-12">
                  學習對話
                  </h3>
                <p className="m-0">
                  教師通過對話使孩子建立日常生活與英語詞彙以及概念的連接。精心設計的課堂文本引導孩子與教師的交流。</p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/conversation.svg')}
                  alt="Features split 02"
                  width={528}
                  height={396}
                  style={{borderRadius:60/2}}/>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  孩子真正參與其中
                  </div>
                <h3 className="mt-0 mb-12">
                  成為會說故事的人
                  </h3>
                <p className="m-0">
                  在細心地提示下，孩子將學會用自己的話來講故事，這是語言理解的重要組成部分。
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/storytelling.svg')}
                //  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} 
                  style={{borderRadius:60/2}}/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
