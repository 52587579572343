import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Bubbles from './illustration-section-01.svg'
import { Helmet } from "react-helmet";

let styles = {
  //  margin: '20px',
   width: '100%',
   maxWidth: '100%',
  //  height: '270px',
//    position: 'absolute',
 //   backgroundPosition: 'center top',
//		transform: 'translate(-50%)',
//    opacity: '80%',
    backgroundOrigin:'absolute',
    backgroundSize:'auto',
    backgroundImage: `url(${Bubbles})`,
    backgroundRepeat: `repeat-x`,
    backgroundPosition: '460px 310px',
   // backgroundColor: 'rgba(255, 255, 255, 0.8)'
  };

const Home = () => {
//      <FeaturesTiles topDivider />
//      <Testimonial topDivider />
      // <Cta split />
      //      <Hero className="illustration-section-01" />
     //      <FeaturesTiles />
  return (
    <>
    
    <Helmet>
      <title>Aesop Learning - Online Reading and Vocabulary Classes for Kids</title>
      <meta name="robots" content="all"/>
      <link rel="alternate" href="https://www.aesoplearning.com/" hrefLang="en"/>
      <link rel="alternate" href="https://www.aesoplearning.com/zh-hk/" hrefLang="zh"/>
      <link rel="alternate" href="https://www.aesoplearning.com/zh-hk/" hrefLang="zh-HK"/>
    </Helmet>
    
      <Hero style={styles} />
      <FeaturesSplit invertMobile imageFill topDivider className="" />
      <Testimonial topDivider />
      <FeaturesTiles />
      <Cta split />

    </>
  );
}

export default Home;
