// JavaScript File
import React from 'react';
import SoftForm from '../components/sections/SoftForm';
import { Helmet } from "react-helmet";


const EngSoftForm = () => {
//      <FeaturesTiles topDivider />
//      <Testimonial topDivider />
  return (
    <>
    
    <Helmet>
      <title>Schedule Your Free Aesop Class</title>
    </Helmet>
    
      <SoftForm />

    </>
  );
}

export default EngSoftForm;