// JavaScript File
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '../layout/partials/Logo';

const PrivacyText = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );
  return (
    <div className= {classes}>
       <div className="container">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
        <Logo />
        </div>
        <br/>
        <div className="header-nav-inner">
                <Link to="/" className="button button-primary button-wide-mobile button-sm" onClick={""}>Go Back</Link>
        </div>
        <br />        
        <div>

         <text className="text-xxs">
<h4>Privacy Policy</h4>
<p>Last updated: February 9, 2022</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator from TermsFeed.</p>
<h4>Interpretation and Definitions</h4>
<h5>Interpretation</h5>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h5>Definitions</h5>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>
<p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
</li>
<li>
<p><strong>Aesop classes or Classes</strong> means online classes offered by the Company.</p>
</li>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Aesop Learning Limited, Unit 1210, W50, 50 Wong Chuk Hang Road, Wong Chuk Hang, Hong Kong SAR.</p>
</li>
<li>
<p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
</li>
<li>
<p><strong>Country</strong> refers to:  Hong Kong SAR China</p>
</li>
<li>
<p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
</li>
<li>
<p><strong>Parent(s)</strong> refers to the parents of legal guardians who purchase classes from the Company for their child to take.</p>
</li>
<li>
<p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
</li>
<li>
<p><strong>Service(s)</strong> refers to the Website and to video classes run by Aesop Learning Limited ("Aesop classes" or "classes").</p>
</li>
<li>
<p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
</li>
<li>
<p><strong>Student</strong> A child whose parents have purchased Classes from the Company to be taught by the Teachers.</p>
</li>
<li>
<p><strong>Teachers</strong> refers to contractors hired by the Company in order to teach Aesop Classes.</p>
</li>
<li>
<p><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
</li>
<li>
<p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
</li>
<li>
<p><strong>Website</strong> refers to Aesop Learning, accessible from <a href="https://aesoplearning.com/" rel="external nofollow noopener" target="_blank">https://aesoplearning.com/</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable, including Parents and Teachers.</p>
</li>
</ul>
<h4>Collecting and Using Your Personal Data</h4>
<h5>Types of Data Collected</h5>
<h6>Personal Data</h6>
<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
<ul>
<li>
<p>Email address</p>
</li>
<li>
<p>First name and last name</p>
</li>
<li>
<p>Phone number</p>
</li>
<li>
<p>Your child’s first name</p>
</li>
<li>
<p>Your child’s age</p>
</li>
<li>
<p>Video recordings of your child or You during Classes</p>
</li>
<li>
<p>Other information voluntarily provided by You pertaining to Classes</p>
</li>
<li>
<p>Usage Data</p>
</li>
</ul>


<h6>Usage Data</h6>
<p>Usage Data is collected automatically when using the Service.</p>
<p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
<p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

<h6>Children</h6>
<p>This section describes Our policies and procedures on information collection, disclosure, parental consent practices and parental choice procedures with respect to information provided by children under the age of 13 (“Child” or “Children”). This policy is in accordance with the Hong Kong Personal Data (Privacy) Ordinance (the "PDPO") and the U.S. Children’s Online Privacy Protection Act (“COPPA”), and outlines our practices in Hong Kong and the United States regarding Children’s personal information.</p>
<ul>
<li><p><strong>Collection:</strong> We collect information about Students directly from Parents, who provide us with the Child’s first name, age, type of schooling, local time zone, and additional notes for the Teacher. In addition, Children may share information about themselves during Classes. The class sessions may take place over online videos in which video images and audio of the Children are recorded. No information is collected directly from Children until they are in the course session. Children cannot post personal data publicly on the Services.</p></li>
<li><p><strong>Use and Disclosure:</strong> We share the name, age, local time zone, country, and any notes Parents have provided about their Child to the Class Teacher, in order to allow the Teacher to provide Classes. This information shall also be treated as part of the Parent’s “Personal Data” and may be shared as described in the Disclosure section above for business transfers; to vendors and service providers; and to comply with legal requirements. Children may also share information about themselves with the Teacher and the rest of their class during the Class session voluntarily. While we expect Teachers and all other Users to abide by our standards of conduct, please note that we cannot control or monitor what personal information your Child shares with Teachers or other classmates, nor what those third parties ultimately do with that information, and we disclaim all responsibility in that regard.</p></li>
<li><p><strong>Class Video Recordings:</strong> As described above, We record video of students and teachers during classes (“Class Recordings”). The Class Recordings may be made available by us to the Teacher of the Class and to the Parents of the Child. We may also use Class Recordings and data derived from Class Recordings to improve the Service offering, to customize the Class content for the Student, to provide feedback to Teachers, provide feedback to Parents, for customer support, and for compliance purposes. We will obtain additional parental consent before we use any Class Recordings for promotional or other purposes. We utilize reasonable means to limit the ability of Teachers to create copies of the Class Recordings or to share the Class Recordings. While we expect Teachers to abide by our standards of conduct, please note that we cannot control or monitor what such third parties ultimately do with Class Recordings, and disclaim all responsibility in that regard.</p></li>
<li><p><strong>Parental Consent:</strong> We obtain verifiable parental consent before collecting Personal Data from your Child. We require that you provide your payment information in order to register your Child for and pay for a class. If you do not consent, then we will not collect, use or disclose any personal information about your Child, and your Child will not be allowed to use the Services in any way. If you are not using our services for any other purposes and do not provide consent within a reasonable time from the date the direct notice was sent, we will also delete your online contact information from our records.</p></li>
<li><p><strong>Parental Choices and Controls:</strong> At any time, you can refuse to permit us to collect further Personal Data from your Children in association with your account, and can request that we delete from our records the Personal Data we have collected in connection with that account. Please keep in mind that a request to delete records may lead to a termination of an account, membership, or other service. You may update your Child’s information by logging onto your account. You can contact us to request access to, change, or delete your Child’s personal information by sending an email to us at privacy@aesoplearning.com. A valid request to delete personal information will be accommodated within a reasonable time. In addition to the foregoing, we will exercise commercially reasonable efforts to delete personal information belonging to Children when it is no longer needed for the purpose for which it was collected.</p></li>
</ul>
<h6>Tracking Technologies and Cookies</h6>
<p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
<ul>
<li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
<li><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.</li>
<li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
</ul>
<p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies here: <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">All About Cookies by TermsFeed</a>.</p>
<p>We use both Session and Persistent Cookies for the purposes set out below:</p>
<ul>
<li>
<p><strong>Necessary / Essential Cookies</strong></p>
<p>Type: Session Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
</li>
<li>
<p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
</li>
<li>
<p><strong>Functionality Cookies</strong></p>
<p>Type: Persistent Cookies</p>
<p>Administered by: Us</p>
<p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
</li>
</ul>
<p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
<h5>Use of Your Personal Data</h5>
<p>The Company may use Personal Data for the following purposes:</p>
<ul>
<li>
<p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
</li>
<li>
<p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
</li>
<li>
<p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
</li>
<li>
<p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
</li>
<li>
<p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
</li>
<li>
<p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
</li>
<li>
<p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
</li>
<li>
<p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
</li>
</ul>
<p>We may share Your personal information in the following situations:</p>
<ul>
<li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
<li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
<li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
<li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
<li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
<li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
</ul>


<h5>Retention of Your Personal Data</h5>
<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
<h5>Transfer of Your Personal Data</h5>
<p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
<h5>Disclosure of Your Personal Data</h5>
<h6>Business Transactions</h6>
<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
<h6>Law enforcement</h6>
<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
<h6>Other legal requirements</h6>
<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
<ul>
<li>Comply with a legal obligation</li>
<li>Protect and defend the rights or property of the Company</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of Users of the Service or the public</li>
<li>Protect against legal liability</li>
</ul>
<h5>Security of Your Personal Data</h5>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<h4>Links to Other Websites</h4>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<h4>Changes to this Privacy Policy</h4>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<h4>Contact Us</h4>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
<li>By email: privacy @ aesoplearning .com</li>
</ul>
         </text>
        </div>
       </div>
    </div>
  );
}

export default PrivacyText;
