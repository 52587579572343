// JavaScript File
import React from 'react';
import PrivacyText from '../components/sections/PrivacyText';
import { Helmet } from "react-helmet";


const Privacy = () => {
//      <FeaturesTiles topDivider />
//      <Testimonial topDivider />
  return (
    <>
    
    <Helmet>
      <title>Privacy Policy</title>
      <meta name="robots" content="noindex"/>
    </Helmet>
    
      <PrivacyText className="" />

    </>
  );
}

export default Privacy;