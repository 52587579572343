import React, { Component } from 'react';
// import "/iframeResizer.min.js";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '../layout/partials/Logo';


const SoftForm = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );
  return (
    <div className={classes}>
      <div className="container">
        <div
          className={classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}
        >
          <Logo />
        </div>
        <br />
        <div className="header-nav-inner">
          <Link
            to="/"
            className="button button-secondary button-wide-mobile button-sm"
            onClick={''}
          >
            Go Back
          </Link>
        </div>
        <div>
          <iframe
            id="softr-2944c6d0-24f6-4917-a0b6-e0fa4c8f0643"
            src="https://client.aesoplearning.com/free-trial"
            width="100%"
            height="1800"
            scrolling="no"
            frameborder="0"
            style={{ border: 'none' }}
          ></iframe>

        </div>
      </div>
    </div>
  );
};

export default SoftForm;
