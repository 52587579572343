import React from 'react';
import HeaderZhhk from '../components/layout/HeaderZhhk';
import FooterZhhk from '../components/layout/FooterZhhk';

const LayoutZhhk = ({ children }) => (
  <>
    <HeaderZhhk navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
      {children}
    </main>
    <FooterZhhk />
  </>
);

export default LayoutZhhk;  