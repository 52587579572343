import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );
//        <li>
//          <a href="https://al3aq30w9an.typeform.com/to/iEkKXdJw">Contact</a>
//        </li>
  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
      
        <li>
          <Link to="/privacy">Privacy</Link>
        </li>
        <li>
          <Link to="/terms">Terms</Link>
        </li>
                <li>
          <Link to="/zh-hk">中文</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;