// JavaScript File
import React from 'react';
import ClientTermsText from '../components/sections/ClientTermsText';
import { Helmet } from "react-helmet";

const ClientTerms = () => {
//      <FeaturesTiles topDivider />
//      <Testimonial topDivider />
  return (
    <>
    <Helmet>
      <title>Client Terms of Use</title>
      <meta name="robots" content="noindex"/>
    </Helmet>
      <ClientTermsText className="" />
    </>
  );
}

export default ClientTerms;