import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    //title: 'Leave Zoom classes behind',
    //paragraph: 'We found that the key to successful remote teaching is continuous two way interaction.'
    title: '開啟課程很簡單',
  //  paragraph: ' Working with developmental psychologists and literacy experts, Aesop has developed a learning system to maximize school readiness '
    
  };

//                                    src={require('./../../assets/images/feature-tile-icon-01.svg')}
//                                    src={require('./../../assets/images/feature-tile-icon-05.svg')}
//                                    src={require('./../../assets/images/feature-tile-icon-06.svg')}
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
        <br />
        <br />
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/cal.svg')}
                      alt="Features tile icon 01"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                   1. 預約
                    </h4>
                  <p className="m-0 text-sm">
                    伊索將會聯繫您並提供鏈接以下載應用程序，同時為您預約適合您的課程時間
                    </p>
                </div>
              </div>
            </div>

 

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/log.svg')}
                      alt="Features tile icon 05"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    2. 登錄
                    </h4>
                  <p className="m-0 text-sm">
                      當課程時間確定且應用程序安裝完畢後，孩子可以按預約的時間進入課程
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/teach.svg')}
                      alt="Features tile icon 06"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    3. 上課
                    </h4>
                  <p className="m-0 text-sm">
                    登錄後老師將出現在視頻聊天畫面中並帶領您的孩子一起度過25分鐘的課程
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
