import React, { Component } from 'react';
// import "/iframeResizer.min.js";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '../layout/partials/Logo';


const Submit = ({

}) => {

  return (
        <div>
          <iframe
            id="softr-2944c6d0-24f6-4917-a0b6-e0fa4c8f0643"
            src="https://client.aesoplearning.com/embed/pages/0ac82c2d-1aa3-4621-a1eb-1e8417b4240e/blocks/other1"
            width="100%"
            height="300"
            scrolling="no"
            frameborder="0"
            style={{ border: 'none' }}
          ></iframe>

        </div>

  );
};

export default Submit;
